import crypto from "crypto";

const mitesHostname = "https://backend.mites.io";
const mitesIP = "128.237.158.19";
const mitesPort = "9443";
// const ENC_KEY = crypto
//   .randomBytes(32)
//   .toString("hex")
//   .slice(0, 32);
// const IV = crypto
//   .randomBytes(16)
//   .toString("hex")
//   .slice(0, 16);
const ENC_KEY = "c8f87a7fbd19ef7af3180b8618f51fea";
const IV = "42a46b7aa7877559";
const bdHostname = "https://external-bd.tcs.mites.io";
const bdCSPort = "81";
const bdDSPort = "82";

// BD RabbitMQ Configuration
const exchangeName = "master_exchange";
const userName = "clientTcsExternal";
const password = "nH^}GW7JUQWhui7ZzQNM";
const virtualHost = "/";
const rabbitMQHostName = "external-bd.tcs.mites.io"; // Hostname of the host

// MLIoT Configuration
const mliotHostname = "https://machinelearninglayer.andrew.cmu.edu";
const mliotPort = 8000;

// BD Configuration
const bdVerifyClientString_1 = "";
const bdVerifyClientString_2 = "";

export default {
  // role: "user" or "admin"
  // indicates whether dashboard is built for user or admin
  // if "user" role, admin-specific routes are not built
  // ref: src/routes/router.js
  role: "user",

  cryptoKeys: {
    ENC_KEY: ENC_KEY,
    IV: IV,
  },
  bdValidateClient: {
    clientString_1: bdVerifyClientString_1,
    clientString_2: bdVerifyClientString_2,
  },
  mitesAddress: {
    hostname: mitesHostname,
    port: mitesPort,
  },

  bdAddress: {
    hostname: bdHostname,
    csPort: bdCSPort,
    dsPort: bdDSPort,
  },

  bdRabbitMQ: {
    hostname: rabbitMQHostName,
    exchangeName: exchangeName,
    userName: userName,
    password: password,
    virtualHost: virtualHost,
  },

  mliotAddress: {
    hostname: mliotHostname,
    port: mliotPort,
  },

  gradientValues: {
    packetRateLow: 200,
    packetRateMedium: 300,
    packetRateHigh: 400,
  },

  rabbitMQSettings: {
    amqpConnection: {
      protocol: "amqp",
      hostname: "localhost",
      port: 5672,
      username: "guest",
      password: "guest",
      locale: "en_US",
      frameMax: 0,
      heartbeat: 0,
      vhost: "/datalog",
    },
    exchange: {
      packetlog: "packetlog",
    },
  },
};

export {
  bdHostname,
  bdCSPort,
  exchangeName,
  userName,
  password,
  virtualHost,
  rabbitMQHostName,
};
