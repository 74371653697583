// Data Connection ////////////////////

import Stomp from "webstomp-client";
import { plotStream } from "../javascripts/PlotGraph.js";
import axios from "axios";
import settings from "@/settings.js";
import swal from "sweetalert2";

let toastInstance = null;
let msgTimeout = null;

/**
 * Subscribes to the mites data stream through building depot.
 * @param {string} routingKey Sensor Id is used as the routing key for RabbitMQ.
 */
let subscriptionId = null;
function getDataStream(routingKey) {
  const ws = new WebSocket(
    "wss://" + settings.bdRabbitMQ.hostname + ":15675/ws"
  ); // Create a websocket object
  const client = Stomp.over(ws); // Use the stomp to make the client object
  // client.debug = null; // Disable the debug messages
  client.debug = () => {};

  // @ToDO: Send request only if the app is subscribed.
  // RABBITMQ SUBSCRIPTION
  console.log("Listening for: " + routingKey);

  /**
   Stream from the broker
   When a new message arrives, the message object gets updated
   */

  const on_connect = function () {
    let message = "";
    // createLivePlotCard(routingKey);
    // populateLiveChannelTable(getLiveSignalNames()); // Opens modal to select the available channels
    // let plotGraph = new PlotGraph();
    // client.debug = null

    const resetTimeout = () => {
      clearTimeout(msgTimeout);
      msgTimeout = setTimeout(showDisconnected, 10000);
    }
    subscriptionId = client.subscribe(
      "/exchange/" + settings.bdRabbitMQ.exchangeName + "/" + routingKey,
      function (d) {
        message = d.body;
        // console.log(message);
        console.log("Data Received.");
        const fullSelectedData = [];
        const selectedChannelsArr = getSelectedLiveSignals();
        // console.log("Selected Singals: ", selectedChannelsArr);
        // Does not plot until the signals are selected
        if (selectedChannelsArr.length !== 0) {
          // Using generated data
          message = message.replace(/u\'/g, '"');
          message = message.replace(/'/g, '"');
          // console.log(message)
          message = JSON.parse(message).fields; // Convert the string to JSON
          // console.log(message)

          // Segregate the data into different plotting types and give the data to the PlotGraph
          segregateData(routingKey, message);

          // Start recording the data
          if (recording_started) {
            record_data(routingKey, message);
          }

          // Start recording the activity data
          if (activityRecordingStarted) {
            recordActivity(routingKey, message);
          }
        }
        // Reset time interval if the message is received
        swal.close();
        resetTimeout();
      }
    );
    // Initial reset
    resetTimeout();
  };
  const on_error = function (e) {
    console.log("Error connecting to the broker");
    console.error(e);
  };
  client.connect(
    settings.bdRabbitMQ.userName,
    settings.bdRabbitMQ.password,
    on_connect,
    on_error,
    settings.bdRabbitMQ.virtualHost
  ); // Callbacks for the connection and error
}

function showDisconnected() {
  toastInstance = swal.fire({
    icon: "error",
    title: "Unable to get data. Please try a different sensor.",
    toast: true,
    position: "top",
    showCancelButton: true,
    confirmButtonText: "Reload Page",
    confirmButtonColor: "#CCCCCC",
    cancelButtonText: "Dismiss",
    cancelButtonColor: "#f27474",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.reload();
    }
  });
}

/**
 * Unsubscribes to the mites data stream through building depot.
 */
function stopDataStream() {
  clearTimeout(msgTimeout);
  try {
    subscriptionId.unsubscribe();
  } catch (err) {
    console.log("Unsubscribe: Queue not found");
  }
}

let selectedChannels = [];
function setSelectedLiveSignals(selectedCheckboxSignals) {
  selectedChannels = selectedCheckboxSignals;
}

function getSelectedLiveSignals() {
  return selectedChannels;
}

// Data Representation ////////////////////

const frequencyDomainRep = "frequency"; // When the signal representation is in frequency domain
const timeDomainRep = "time"; // When the signal representation is in time domain
const colorDomainRep = "color"; // When the signal representation is in time domain

/**
 * Function defines all the channel names. The data for each key is received as an array.
 * Significance of the sensor signals with 7 values (index of the array) when the signal is in time domain:
 * 0: Minimum
 * 1: Maximum
 * 2: Summation
 * 3: Average
 * 4: Standard Deviation
 * 5: Range
 * 6: Centroid
 * @returns {string[]} List of all the signals of the device.
 */
function getLiveSignalNames() {
  // Must be same as the the signals from the streaming message
  return [
    {
      signalName: "AccelerometerX",
      signalRepresentation: frequencyDomainRep,
      mitesKey: "Accelerometer-0",
    },
    {
      signalName: "AccelerometerY",
      signalRepresentation: frequencyDomainRep,
      mitesKey: "Accelerometer-1",
    },
    {
      signalName: "AccelerometerZ",
      signalRepresentation: frequencyDomainRep,
      mitesKey: "Accelerometer-2",
    },
    {
      signalName: "Barometer",
      signalRepresentation: timeDomainRep,
      mitesKey: "Barometer",
    },
    {
      signalName: "Color0",
      signalRepresentation: colorDomainRep,
      mitesKey: "Color0",
    },
    {
      signalName: "Color1",
      signalRepresentation: colorDomainRep,
      mitesKey: "Color1",
    },
    {
      signalName: "Color2",
      signalRepresentation: colorDomainRep,
      mitesKey: "Color2",
    },
    {
      signalName: "EMI",
      signalRepresentation: frequencyDomainRep,
      mitesKey: "EMI",
    },
    {
      signalName: "GridEye",
      signalRepresentation: frequencyDomainRep,
      mitesKey: "Grid-Eye",
    },
    {
      signalName: "Humidity",
      signalRepresentation: timeDomainRep,
      mitesKey: "Humidity",
    },
    {
      signalName: "Light",
      signalRepresentation: timeDomainRep,
      mitesKey: "Light",
    },
    {
      signalName: "Motion",
      signalRepresentation: timeDomainRep,
      mitesKey: "Motion",
    },
    {
      signalName: "Magnetometer0",
      signalRepresentation: timeDomainRep,
      mitesKey: "Magnetometer-0",
    },
    {
      signalName: "Magnetometer1",
      signalRepresentation: timeDomainRep,
      mitesKey: "Magnetometer-1",
    },
    {
      signalName: "Magnetometer2",
      signalRepresentation: timeDomainRep,
      mitesKey: "Magnetometer-2",
    },
    {
      signalName: "Microphone",
      signalRepresentation: frequencyDomainRep,
      mitesKey: "Microphone",
    },
    {
      signalName: "Temperature",
      signalRepresentation: timeDomainRep,
      mitesKey: "Temperature",
    },
    {
      signalName: "Wifi",
      signalRepresentation: timeDomainRep,
      mitesKey: "Wifi",
    },
  ];
}

/**
 * Finds the object which has the given value for a particular key.
 * Here it finds the object whose signal name is [signalName]
 * @param signalName Name of the signal whose object is requested
 * @returns {string[]} Returns the object in an array
 */
function getSignalObject(signalName) {
  const signalArray = getLiveSignalNames();
  return signalArray.filter(function (signalArray) {
    if (signalName === "Color") {
      return signalArray.signalName === "Color0";
    } else {
      return signalArray.signalName === signalName;
    }
  });
}

// Static Data (Optional) ////////////////////

/**
 * Function defines all the signal names. The data for each key is received as an array.
 * Significance of the sensor signals with 7 values (index of the array) when the signal is in time domain:
 * 0: Minimum
 * 1: Maximum
 * 2: Summation
 * 3: Average
 * 4: Standard Deviation
 * 5: Range
 * 6: Centroid
 * @returns {string[]} List of all the signals of the device.
 */
function getStaticSignalNames() {
  // Contains only half the key
  return [
    {
      signalKey: "Mic-0_",
      signalName: "Microphone",
      signalRepresentation: frequencyDomainRep,
    },
    {
      signalKey: "Accel-0_",
      signalName: "AccelX",
      signalRepresentation: frequencyDomainRep,
    },
    {
      signalKey: "Accel-1_",
      signalName: "AccelY",
      signalRepresentation: frequencyDomainRep,
    },
    {
      signalKey: "Accel-2_",
      signalName: "AccelZ",
      signalRepresentation: frequencyDomainRep,
    },
    {
      signalKey: "EMI-0_",
      signalName: "EMI",
      signalRepresentation: frequencyDomainRep,
    },
    {
      signalKey: "Temp.-0_",
      signalName: "Temperature",
      signalRepresentation: timeDomainRep,
    },
    {
      signalKey: "Baro.-0_",
      signalName: "Barometer",
      signalRepresentation: timeDomainRep,
    },
    {
      signalKey: "Hum.-0_",
      signalName: "Humidity",
      signalRepresentation: timeDomainRep,
    },
    {
      signalKey: "Light-0_",
      signalName: "Light",
      signalRepresentation: timeDomainRep,
    },
    {
      signalKey: "Motion-0_",
      signalName: "Motion",
      signalRepresentation: timeDomainRep,
    },
    {
      signalKey: "Color-0_",
      signalName: "Color",
      signalRepresentation: frequencyDomainRep,
    },
    {
      signalKey: "EMI-0_",
      signalName: "Magnetic",
      signalRepresentation: timeDomainRep,
    },
    {
      signalKey: "EMI-0_",
      signalName: "WiFiRSSI",
      signalRepresentation: timeDomainRep,
    },
    {
      signalKey: "EMI-0_",
      signalName: "GridEye",
      signalRepresentation: timeDomainRep,
    },
  ];
}

/**
 * Finds the object which has the given value for a particular key.
 * Here it finds the object whose signal name is [signalName]
 * @param signalKey Key of the signal whose object is requested
 * @returns {string[]} Returns the object in an array
 */
function getStaticSignalObject(signalKey) {
  const signalArray = getStaticSignalNames();
  return signalArray.filter(function (signalArray) {
    return signalArray.signalName === signalKey;
  });
}

// Data Segregation and Plot ////////////////////
/**
 * Segregates the data from the selected signals based on its representation (spectrogram of time series).
 * Creates an object of all the information required to plot.
 * Sample format of the data to plot:
 * {
 *     deviceId: "2d001c000d47363330353437",
 *     spectrogramData: [
 *         signalData: [fft values...],
 *         signalName: "Accelerometer-0"
 *     ],
 *     timeSeriesData: [
 *         signalData: 28,
 *         signalName: "Temperature"
 *     ]
 * }
 * @param deviceId Id of the device
 * @param signalMessage Message from the RabbitMQ queue
 */
function segregateData(deviceId, signalMessage) {
  console.log(signalMessage);
  const selectedChannelsArr = getSelectedLiveSignals();
  const timeSeriesData = [];
  const spectrogramData = [];
  const colorData = [];
  const signalObj = {};
  for (const i in selectedChannelsArr) {
    const signalInfoObject = getSignalObject(selectedChannelsArr[i]);

    const timeDataObj = {};
    if (signalInfoObject[0].signalRepresentation === "time") {
      timeDataObj.signalName = selectedChannelsArr[i];
      timeDataObj.signalData =
        signalMessage[signalInfoObject[0].mitesKey + "-3"]; // Signal data
      timeSeriesData.push(timeDataObj);
    }
    if (signalInfoObject[0].signalRepresentation === "frequency") {
      let singleSensorData = [];
      for (let j in signalMessage) {
        if (j.includes(signalInfoObject[0].mitesKey)) {
          singleSensorData.push(signalMessage[j]);
        }
      }
      timeDataObj.signalName = selectedChannelsArr[i];
      timeDataObj.signalData = singleSensorData; // Signal data

      spectrogramData.push(timeDataObj);
    }
    if (signalInfoObject[0].signalRepresentation === "color") {
      timeDataObj.signalName = selectedChannelsArr[i];
      timeDataObj.signalData = [
        signalMessage["Color0-3"],
        signalMessage["Color1-3"],
        signalMessage["Color2-3"],
      ]; // Signal data
      colorData.push(timeDataObj);
    }
  }
  signalObj.deviceId = deviceId;
  signalObj.timeSeriesData = timeSeriesData;
  signalObj.spectrogramData = spectrogramData;
  signalObj.colorData = colorData;
  // console.log("################# Signal Object");
  // console.log(signalObj);
  plotStream(signalObj); // Pass the data to the PlotGraph

  // Recording: New Implementation: Mark the timestamps from parent sensor
  lastLiveDataTimeStamp = signalMessage["inserted_at"];
}

// Record Data ////////////////////
var recording_started = false;
let recorded_data = [];
let recordedTimestamps = [];
var mitesDataKeys = [
  "Accelerometer-0",
  "Accelerometer-1",
  "Accelerometer-2",
  "Barometer",
  "Color0",
  "Color1",
  "Color2",
  "EMI",
  "Grid-Eye",
  "Humidity",
  "Light",
  "Magnetometer-0",
  "Magnetometer-1",
  "Magnetometer-2",
  "Microphone",
  "Temperature",
  "Motion",
  "Wifi",
];

function record_data(deviceId, signalMessage) {
  console.log("Recording: ", deviceId);

  var recordObj = {};
  // Initialize record obj
  for (let i in mitesDataKeys) {
    recordObj[mitesDataKeys[i]] = [];
  }
  console.log(signalMessage["inserted_at"]);
  // recordObj.TimeStamp = signalMessage.TimeStamp
  // recordObj.UnixTimeStamp = signalMessage.UnixTimeStamp

  for (let sigKey in signalMessage) {
    for (let j in mitesDataKeys) {
      if (sigKey.includes(mitesDataKeys[j])) {
        recordObj[mitesDataKeys[j]].push(signalMessage[sigKey]);
      }
    }
  }
  // Add to the array of each message
  if (!activityRecordingPaused) {
    recorded_data.push(recordObj);
  }
  let convertedTime = new Date(signalMessage["inserted_at"]).getTime() / 1000;
  recordedTimestamps.push(convertedTime);
  console.log(recorded_data.length, recordedTimestamps.length);
}

/**
 * Enables the recordingStarted condition. Used as the button event listener in the UI.
 */
function start_recording() {
  console.log("Recording Started.");
  recording_started = true;
}

/**
 * Disables the recordingStarted condition. Used as the button event listener in the UI.
 * Parse the recorded data and save it in the file using papa parse.
 */
function stop_save_recording(
  sessionName,
  selectedSensor,
  parentSensor,
  accessToken
) {
  // For session info
  console.log("Num of rows: ", recorded_data.length);
  var sessionLength = recorded_data.length;

  function addBuildingTag(sensorId) {
    let data = {
      data: {
        name: "parent",
        value: sensorId,
        parents: [],
      },
    };
    axios
      .post(
        "https://bd-test.andrew.cmu.edu:81/api/building/WeanHall/tags",
        data,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log("Error");
        console.log(error);
      });
  }

  // Post time series
  function postTimeSeries(rowIdx, sensorId, timeNow) {
    console.log(recordedTimestamps.length);
    // Create data object to post time series
    let recordedTime = recordedTimestamps[rowIdx];
    console.log(recordedTime);
    var samplesObj = recorded_data[rowIdx];
    samplesObj["time"] = recordedTime;
    // samplesObj['time'] = recordedTime;
    // // Upload the data to BD time series
    var dataObj = {};
    dataObj.sensor_id = sensorId; // Sensor Id of the sensor where data will be stored
    dataObj.samples = [samplesObj];
    var obj = {};
    obj.data = [dataObj];
    console.log(obj);
    axios
      .post("https://bd-test.andrew.cmu.edu:82/api/sensor/timeseries", obj, {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        // this.response = response.data;
      });
  }

  // Create new and post training data
  if (selectedSensor === "" && sessionName !== "") {
    // Create a new sensor
    let data = {
      data: {
        name: "training_recording_session",
        identifier: "record_session_" + parentSensor + "_" + sessionName,
        building: "WeanHall",
      },
    };
    axios
      .post("https://bd-test.andrew.cmu.edu:81/api/sensor", data, {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Add Building Tag to the new sensor
        addBuildingTag(response.data["uuid"]);

        let ssStartTime = 0;
        let ssEndTime = 0;
        for (let i in recorded_data) {
          let currentTime = Date.now() / 1000;
          // Note start and time
          if (i == 0) {
            ssStartTime = currentTime;
          }
          if (i == recorded_data.length - 1) {
            ssEndTime = currentTime;
          }
          postTimeSeries(i, response.data["uuid"], currentTime);
        }
        // Post session to BD tags
        postSessionMetadata(response.data["uuid"], ssStartTime, ssEndTime);
      });
  }
  // Post training data to selected sensor
  else {
    var sessionStartTime = 0;
    var sessionEndTime = 0;
    for (let i in recorded_data) {
      let currentTime = Date.now() / 1000;
      // Note start and time
      if (i == 0) {
        sessionStartTime = currentTime;
      }
      if (i == recorded_data.length - 1) {
        sessionEndTime = currentTime;
      }
      postTimeSeries(i, selectedSensor, currentTime);
    }
    // Post session to BD tags
    postSessionMetadata(selectedSensor, sessionStartTime, sessionEndTime);
  }

  function postSessionMetadata(sensorMetaId, startTime, endTime) {
    // Post session info to tags
    let tagSessionInfo = {
      start_time: recordedTimestamps[0],
      end_time: recordedTimestamps[recordedTimestamps.length - 1],
      session_name: sessionName,
      session_length: sessionLength,
    };
    let tagParentSensor = parentSensor;
    var metadataObj = {
      data: {
        tags: [
          {
            name: "Session",
            value: JSON.stringify(tagSessionInfo),
          },
          {
            name: "ParentSensor",
            value: tagParentSensor,
          },
        ],
      },
    };
    // Upload the metadata to BD tags
    axios
      .post(
        "https://bd-test.andrew.cmu.edu:81/api/sensor/" +
          sensorMetaId +
          "/tags",
        metadataObj,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
      });
  }
}

// Record Activity Functions ///////////////////////////////

var activityRecordingStarted = false;
var activityRecordingPaused = false;
var recordedActivityData = [];
let recordedActivityTimestamps = [];
var currentActivity = "None";
var allActivities = [];

// For deleting an activity
var currentActivityIndex = 0;
var activityIndices = [];

// For getting the number of samples added
var numSamples = 0; // Number of samples per activity

// Recording: New Implementation: Mark the timestamps from parent sensor
var allRecordingTimeStamps = [];
var lastLiveDataTimeStamp = 0;
var currentActivityStartTime = 0;
var currentActivityEndTime = 0;

/**
 * Enables the recordingStarted condition. Used as the button event listener in the UI.
 */
function start_recording_activity(iniActivity) {
  console.log("Recording Started: ", iniActivity);
  activityRecordingStarted = true;
  activityRecordingPaused = false;
  currentActivity = iniActivity;

  // Recording: New Implementation: Mark the timestamps from parent sensor
  currentActivityStartTime = getLastLiveDataTimeStamp();
}

/**
 * Enables the recordingStarted condition. Used as the button event listener in the UI.
 */
function pauseRecordingActivity() {
  console.log("Pause Started.");
  activityRecordingPaused = true;
  currentActivityIndex += 1;

  // Recording: New Implementation: Mark the timestamps from parent sensor
  currentActivityEndTime = getLastLiveDataTimeStamp();
  let sessObj = {
    activityLabel: currentActivity,
    startTime: currentActivityStartTime,
    endTime: currentActivityEndTime,
    activityId: "activity_" + allRecordingTimeStamps.length,
    sampleCount: getNumSamples(),
  };
  allRecordingTimeStamps.push(sessObj);
  console.log(allRecordingTimeStamps);

  numSamples = 0; // Reset the number of samples of a single activity
}

/**
 * // Recording: New Implementation: Mark the timestamps from parent sensor
 */
function addExistingActivities(existingActivities) {
  allRecordingTimeStamps = [];
  console.log("Adding Existing Activities.", existingActivities);
  for (let i in existingActivities) {
    let sessObj = {
      activityLabel: existingActivities[i].activityLabel,
      startTime: existingActivities[i].startTime,
      endTime: existingActivities[i].endTime,
      activityId: existingActivities[i].activityId,
      sampleCount: existingActivities[i].sampleCount,
    };
    allRecordingTimeStamps.push(sessObj);
  }
  console.log(allRecordingTimeStamps);
}

/**
 * Deletes an activity.
 */
function deleteActivity(actName, actIndex) {
  console.log("Delete Activity: ", actName, actIndex);
  console.log(allRecordingTimeStamps);
  // Get indices of all the activity data to delete
  console.log(currentActivityIndex);
  console.log(activityIndices);
  let delIndices = [];
  for (let i = 0; i < activityIndices.length; i++) {
    if (activityIndices[i] == actIndex) {
      delIndices.push(i);
    }
  }

  let removeStartIdx = delIndices[0];
  let removeEndIdx = delIndices[delIndices.length - 1];

  // Update the values of all the indices after the removed activity indices
  for (let i = removeEndIdx; i < activityIndices.length; i++) {
    activityIndices[i] -= 1;
  }

  // Delete the values from the data
  activityIndices.splice(removeStartIdx, removeEndIdx + 1);
  recordedActivityData.splice(removeStartIdx, removeEndIdx + 1);
  recordedActivityTimestamps.splice(removeStartIdx, removeEndIdx + 1);

  // Reduce the current index by 1
  currentActivityIndex -= 1;

  // Recording: New Implementation: Mark the timestamps from parent sensor
  allRecordingTimeStamps.splice(actIndex, 1);
  console.log(allRecordingTimeStamps);
}

/**
 * Returns the number of sample recorded.
 */
function getNumSamples() {
  return numSamples;
}

/**
 * Returns the most recent data timestamp.
 */
// Recording: New Implementation: Mark the timestamps from parent sensor
function getLastLiveDataTimeStamp() {
  return lastLiveDataTimeStamp;
}

function recordActivity(deviceId, signalMessage) {
  console.log("Recording: ", deviceId);

  var recordObj = {};
  // Initialize record obj
  for (let i in mitesDataKeys) {
    recordObj[mitesDataKeys[i]] = [];
  }
  console.log(signalMessage["inserted_at"]);
  // recordObj.TimeStamp = signalMessage.TimeStamp
  // recordObj.UnixTimeStamp = signalMessage.UnixTimeStamp

  for (let sigKey in signalMessage) {
    for (let j in mitesDataKeys) {
      if (sigKey.includes(mitesDataKeys[j])) {
        recordObj[mitesDataKeys[j]].push(signalMessage[sigKey]);
      }
    }
  }
  // Add to the array of each message
  if (!activityRecordingPaused) {
    activityIndices.push(currentActivityIndex);
    recordedActivityData.push(recordObj);
    allActivities.push(currentActivity);
    let convertedTime = new Date(signalMessage["inserted_at"]).getTime() / 1000;
    recordedActivityTimestamps.push(convertedTime);
    numSamples += 1; // Increase the number of samples of a single activity
    console.log(
      recordedActivityData.length,
      recordedActivityTimestamps.length,
      allActivities.length
    );
  }
}

/**
 * Disables the recordingStarted condition. Used as the button event listener in the UI.
 * Parse the recorded data and save it in the file using papa parse.
 */
async function saveActivity(
  sessionName,
  selectedSensor,
  parentSensor,
  accessToken,
  selectedViews
) {
  console.log(sessionName);
  console.log(selectedSensor);
  console.log(parentSensor);
  console.log("Selected Mites Views: ", selectedViews);

  // For session info
  console.log("Num of rows: ", recordedActivityData.length);
  var sessionLength = recordedActivityData.length;

  // Create a new virtual sensor and post training data
  console.log(
    settings.bdAddress.hostname +
      ":" +
      settings.bdAddress.csPort +
      "/api/sensor"
  );
  if (selectedSensor === "" && sessionName !== "") {
    // Create a new sensor
    let data = {
      data: {
        name: "training_recording_session",
        identifier: "record_session_" + parentSensor + "_" + sessionName,
        building: "TCS_Building",
      },
    };
    axios
      .post(
        settings.bdAddress.hostname +
          ":" +
          settings.bdAddress.csPort +
          "/api/sensor",
        data,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("New Sensor Created for Training Session: ", response.data);
        // Post session to BD tags
        postSessionTags(response.data["uuid"]);
      });
  }
  // Post training data to selected sensor
  else {
    postSessionTags(selectedSensor);
  }

  /**
   * STEP 5
   * Add event labels in the tags.
   */
  function postSessionTags(sensorId) {
    let oldSession = [];

    // Add selected sensors in the tag
    let tagSelectedSensors = selectedViews;
    // let tagSelectedSensors = ['Barometer', 'Humidity', 'Light'] ;
    let sensorsObj = {
      name: "Sensors",
      value: JSON.stringify(tagSelectedSensors),
    };
    oldSession.push(sensorsObj);

    // Save the activity timestamps object
    let activitySessionObj = {
      name: "ActivitySessions",
      value: JSON.stringify(allRecordingTimeStamps),
    };
    oldSession.push(activitySessionObj);

    // Final Object
    var metadataObj = {
      data: {
        tags: oldSession,
      },
    };

    console.log(metadataObj);

    axios
      .post(
        settings.bdAddress.hostname +
          ":" +
          settings.bdAddress.csPort +
          "/api/sensor/" +
          sensorId +
          "/tags",
        metadataObj,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
      });
  }
}

export {
  getDataStream,
  stopDataStream,
  setSelectedLiveSignals,
  start_recording,
  stop_save_recording,
  start_recording_activity,
  saveActivity,
  pauseRecordingActivity,
  deleteActivity,
  getNumSamples,
  addExistingActivities,
};
